import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  DialogActions, 
  Button, 
  TextField, 
  CircularProgress, 
  Typography, 
  Alert, 
  Autocomplete 
} from '@mui/material';
import { useCopyFiles } from './queries'; 

export const CopyFilesDialog = ({
  open,
  setOpen,
  selectedFiles,
  refetch,
  datasets,
  setSelectedFiles
}) => {
  const [selectedDataset, setSelectedDataset] = React.useState(null);
  const { fetching, warning, error, copyFiles } = useCopyFiles();

  const handleClose = () => {
    if (!fetching) {
      setOpen(false);
    }
  };

  const handleCopy = async () => {
    if (!selectedDataset) {
      return;
    }

    const success = await copyFiles(selectedFiles, selectedDataset);
    if (success) {
      refetch(); 
      setOpen(false); 
      setSelectedFiles([]); 
    }
  };

  const selectDataset = (event, newValue) => {
    setSelectedDataset(newValue);
  };

  const userDatasets = React.useMemo(() => {
    return datasets
      .filter(dataset => /user_custom_\d+_[^_]+_content/.test(dataset))
      .map(dataset => {
        const match = dataset.match(/user_custom_\d+_([^_]+)_content/);
        return match && match[1] ? match[1] : '';
      })
      .filter(dataset => dataset !== ''); 
  }, [datasets]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
      <DialogTitle>Copy Files</DialogTitle>
      {!fetching && (
        <DialogContent>
          <DialogContentText>
            Are you sure you want to copy the {selectedFiles.length} file{selectedFiles.length !== 1 ? 's' : ''}?
          </DialogContentText>
          <Typography variant='subtitle2' sx={{ mt: 2, mb: 1 }}>
            Select a dataset to copy the files to:
          </Typography>
          {userDatasets.length === 0 ? (
            <Alert severity='info' sx={{ mt: 2 }}>
              No available datasets to copy files to.
            </Alert>
          ) : (
            <Autocomplete
              value={selectedDataset}
              onChange={selectDataset}
              size='small'
              fullWidth
              options={userDatasets}
              renderInput={(params) => <TextField {...params} label="Dataset" />}
              disableClearable
            />
          )}
          {error && <Alert severity='error' sx={{ mt: 2 }}>{error}</Alert>}
          {warning && <Alert severity='warning' sx={{ mt: 2 }}>{warning}</Alert>}
        </DialogContent>
      )}
      {fetching && (
        <DialogContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <DialogContentText sx={{ mb: 2 }}>
            Copying files...
          </DialogContentText>
          <CircularProgress />
        </DialogContent>
      )}
      <DialogActions>
        <Button 
          onClick={handleCopy} 
          variant='contained' 
          disabled={!selectedDataset || fetching} 
        >
          Copy
        </Button>
        <Button 
          onClick={handleClose} 
          variant='contained' 
          color='error' 
          disabled={fetching}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
