import React, { useState } from "react"
import { Button, Collapse, Menu, MenuItem, Stack, Switch, TextField, Typography } from "@mui/material"
import { MdAdd, MdKeyboardArrowDown, MdMenu } from "react-icons/md"
import { FiFilter, FiUploadCloud } from "react-icons/fi";
import AddDatasetPopup from "../AddDatasetPopup";

export const FileManagementFilters = ({
  groupDatasets,
  setGroupDatasets,
  filterFilename,
  setFilterFilename,
  filterDataset,
  setFilterDataset,
  openAll,
  closeAll,
  openFiles,
  openDatasets,
  loadDatasets,
  triggerIngestPopup,
}) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [triggerDatasetPopup, setTriggerDatasetPopup] = useState(0);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <Button 
        onClick={()=> {setFiltersOpen(!filtersOpen)}} 
        startIcon={<FiFilter/>}
        sx={{ display: {xs: 'row', md: 'none'}, padding: 1, marginBottom: 2}}
      > 
        Filters 
      </Button>
      <Stack 
        display={{xs: filtersOpen ? 'block' : 'none', md: 'flex'}} 
        direction={{xs: 'column', md: 'row'}} 
        justifyContent={'space-between'}
        mb={2}
      >
        <Stack direction={{xs: 'column', md: 'row'}} spacing={1} paddingX={{xs: 1, md: 0}}>
          <Stack direction="column" spacing={0}>
            <Typography variant="body2">Filter By Filename: </Typography>
            <TextField
              placeholder="Search"
              variant="outlined"
              size="small"
              value={filterFilename}
              onChange={(e) => setFilterFilename(e.target.value)}
            />
          </Stack>
          <Stack direction="column" spacing={0}>
            <Typography variant="body2">Filter By Dataset: </Typography>
            <TextField
              placeholder="Search"
              variant="outlined"
              size="small"
              value={filterDataset}
              onChange={(e) => setFilterDataset(e.target.value)}
            />
          </Stack>
          <Stack direction={{ xs: 'row', md: 'column'}} spacing={0} alignItems={'center'}>
            <Typography variant="body2">Group Dataset: </Typography>
            <Switch
              checked={groupDatasets}
              onChange={() => setGroupDatasets(!groupDatasets)}
              color="primary"
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={1} alignItems={'flex-end'} paddingX={{xs: 1, md: 0}}>
          {/* <Button
            startIcon={<FiUploadCloud />}
            variant="contained"
            sx={{ height: '40px' }}
            onClick={() => triggerIngestPopup()} 
          >
            Ingest
          </Button> */}
          <Button 
            startIcon={<MdAdd />}
            variant="contained"
            sx={{ height: '40px' }}
            onClick={() => setTriggerDatasetPopup(1)} 
          >
            Dataset
          </Button>
          { groupDatasets && (
            <Stack direction="row" spacing={0}>
              <Button 
                startIcon={<MdMenu />}
                variant="contained"
                sx={{ height: '40px', borderRadius: '4px 0 0 4px' }}
                onClick={openFiles.length > 0 || openDatasets.length > 0 ? closeAll : openAll}
              >
                {openFiles.length > 0 || openDatasets.length > 0 ? 'Collapse': 'Expand'}
              </Button>
              <Button 
                variant="contained"
                sx={{ height: '40px', minWidth: '0', padding: '0', borderRadius: '0 4px 4px 0' }}
                onClick={handleClick}
              >
                <MdKeyboardArrowDown size={24} />
              </Button>
            </Stack>
          )}
        </Stack>
        {triggerDatasetPopup === 1 &&
          <AddDatasetPopup loadDatasets={loadDatasets} setTriggerDatasetPopup={setTriggerDatasetPopup} triggerDatasetPopup={triggerDatasetPopup} />
        }
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MenuItem 
            onClick={()=>{
              openAll();
              handleClose();
            }}
          >
            Expand All
          </MenuItem>
          <MenuItem 
            onClick={()=>{ 
              closeAll();
              handleClose();
            }}
          >
            Collapse All
          </MenuItem>
        </Menu>
      </Stack>
    </>
  )
}

