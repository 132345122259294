import * as default_data from '../config.js'
import { getToken } from '../AuthService.js';
import { getAllowedModels } from '../Utils/model_helper.js'

async function proposeModelCount(token_count, file=null) {
  var model = 'gpt';
  var models_available = ['gpt-4o', 'gpt35-16k', 'gpt-4o-mini', 'gpt', 'gpt4', 'gpt4-32k', 'gpt4-vision', 'gpt-4o-gov', 'gpt-gov', 'gpt4-gov']

  models_available = models_available.filter((option) => getAllowedModels().includes(option));

  if(token_count === null) {
    return model;
  }

  if(file !== null && file !== '') {
    if(models_available.includes('gpt-4o-mini')) {
      model = 'gpt-4o-mini';
      return model;
    }
    else if(models_available.includes('gpt-4o')) {
      model = 'gpt-4o';
      return model;
    }
    else if(models_available.includes('gpt-4o-gov')) {
      model = 'gpt-4o-gov';
      return model;
    }
  }

  model = null;
  // loop through available models
  for(var i = 0; i < models_available.length; i++) {
    var token_window = getTokenWindowR(models_available[i]);
    if(token_count <= (token_window * 3 / 5)) {
      model = models_available[i];
      break;
    }
  }

  return model;
}

function getTokenWindowR(currentModel) {
  if(currentModel.toLowerCase() === 'gpt' || currentModel.toLowerCase() === 'openai_gpt') {
    return 4096;
  }
  else if(currentModel.toLowerCase() === 'gpt-gov') {
    return 16384;
  }
  else if(currentModel.toLowerCase() === 'cohere') {
    return 4096;
  }
  else if(currentModel.toLowerCase() === 'groq-70b') {
    return 8192;
  }
  else if(currentModel.toLowerCase() === 'xai-grok') {
    return 131072;
  }  
  else if(currentModel.toLowerCase() === 'mpt-7b-chat') {
    return 4096;
  }
  else if(currentModel.toLowerCase() === 'dolly') {
    return 4096;
  }
  else if(currentModel.toLowerCase() === 'flan-t5') {
    return 4096;
  }
  else if(currentModel.toLowerCase() === 'claude2') {
    return 100000;
  }
  else if(currentModel.toLowerCase() === 'claude-3-opus') {
    return 200000;
  }
  else if(currentModel.toLowerCase() === 'claude-3-sonnet') {
    return 200000;
  }
  else if(currentModel.toLowerCase() === 'claude-35-sonnet') {
    return 200000;
  }
  else if(currentModel.toLowerCase() === 'llma2') {
    return 8000;
  }
  else if(currentModel.toLowerCase() === 'llma3') {
    return 8000;
  }
  else if(currentModel.toLowerCase() === 'falcon') {
    return 4096;
  }
  else if(currentModel.toLowerCase() === 'google-bison') {
    return 8192;
  }
  else if(currentModel.toLowerCase() === 'aws-bedrock-titan') {
    return 8192;
  }
  else if(currentModel.toLowerCase() === 'aws-bedrock-claude-35-sonnet-gov') {
    return 200000;
  }
  else if(currentModel.toLowerCase() === 'google-gemini-pro') {
    return 32000;
  }
  else if(currentModel.toLowerCase() === 'mistral-large') {
    return 32000;
  }
  else if(currentModel.toLowerCase() === 'gpt4') {
    return 8192;
  }
  else if(currentModel.toLowerCase() === 'gpt4-gov') {
    return 128000;
  }
  else if(currentModel === 'gpt4-32k') {
    return 32768;
  }
  else if(currentModel === 'gpt4-vision') {
    return 128000;
  }
  else if(currentModel === 'auto') {
    return 128000;
  }
  else if(currentModel === 'gpt-4o') {
    return 128000;
  }
  else if(currentModel === 'gpt-4o-mini') {
    return 128000;
  }
  else if(currentModel === 'gpt-o1-mini') {
    return 128000;
  }
  else if(currentModel === 'gpt-o1') {
    return 128000;
  }
  else if(currentModel === 'gpt-4o-gov') {
    return 128000;
  }
  else if(currentModel === 'dall-e-2') {
    return 1024;
  }
  else if(currentModel === 'dall-e-3') {
    return 1024;
  }
  else if(currentModel === 'gpt35-16k') {
    return 16000;
  }
  return 4096;
}

function isCUICapableR(currentModel) {
  if(currentModel.toLowerCase() === 'gpt' || currentModel.toLowerCase() === 'openai_gpt') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'gpt-gov') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'cohere') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'groq-70b') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'xai-grok') {
    return false;
  }  
  else if(currentModel.toLowerCase() === 'mpt-7b-chat') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'dolly') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'flan-t5') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'claude2') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'claude-3-opus') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'claude-3-sonnet') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'claude-35-sonnet') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'llma2') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'llma3') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'falcon') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'google-bison') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'aws-bedrock-titan') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'aws-bedrock-claude-35-sonnet-gov') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'google-gemini-pro') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'mistral-large') {
    return false;
  }
  else if(currentModel.toLowerCase() === 'gpt4') {
    return true;
  }
  else if(currentModel.toLowerCase() === 'gpt4-gov') {
    return true;
  }
  else if(currentModel === 'gpt4-32k') {
    return true;
  }
  else if(currentModel === 'auto') {
    return true;
  }
  else if(currentModel === 'gpt4-vision') {
    return true;
  }
  else if(currentModel === 'gpt-4o') {
    return true;
  }
  else if(currentModel === 'gpt-4o-mini') {
    return true;
  }
  else if(currentModel === 'gpt-o1') {
    return true;
  }
  else if(currentModel === 'gpt-o1-mini') {
    return true;
  }
  else if(currentModel === 'gpt-4o-gov') {
    return true;
  }
  else if(currentModel === 'dall-e-2') {
    return false;
  }
  else if(currentModel === 'dall-e-3') {
    return true;
  }
  else if(currentModel === 'gpt35-16k') {
    return true;
  }
  return false;
}

async function getModelInfoR(currentModel, tokens_count, file) {
  var new_model = currentModel

  if(new_model.toLowerCase() === 'auto') {
    new_model = await proposeModelCount(tokens_count, file)
  }

  var model = 'openai_gpt';
  var history_count = 10;
  if(new_model.toLowerCase() == 'gpt' || new_model.toLowerCase() == 'openai_gpt') {
    model = 'openai_gpt'
  }
  else if(new_model.toLowerCase() == 'gpt-gov') {
    model = 'gpt-gov'
  }
  else if(new_model.toLowerCase() == 'cohere') {
    model = 'cohere'
  }
  else if(new_model.toLowerCase() == 'groq-70b') {
    model = 'groq-70b'
  }
  else if(new_model.toLowerCase() == 'xai-grok') {
    model = 'xai-grok'
  }
  else if(new_model.toLowerCase() == 'mpt-7b-chat') {
    model = 'mpt-7b-chat'
  }
  else if(new_model.toLowerCase() == 'dolly') {
    model = 'dolly'
  }
  else if(new_model.toLowerCase() == 'flan-t5') {
    model = 'flan-t5'
  }
  else if(new_model.toLowerCase() == 'claude2') {
    model = 'claude2'
    history_count = 30;
  }
  else if(new_model.toLowerCase() == 'claude-3-opus') {
    model = 'claude-3-opus'
    history_count = 30;
  }
  else if(new_model.toLowerCase() == 'claude-3-sonnet') {
    model = 'claude-3-sonnet'
    history_count = 30;
  }
  else if(new_model.toLowerCase() == 'claude-35-sonnet') {
    model = 'claude-35-sonnet'
    history_count = 30;
  }
  else if(new_model.toLowerCase() == 'llma2') {
    model = 'llma2'
  }
  else if(new_model.toLowerCase() == 'llma3') {
    model = 'llma3'
  }
  else if(new_model.toLowerCase() == 'falcon') {
    model = 'falcon'
  }
  else if(new_model.toLowerCase() == 'google-bison') {
    model = 'google-bison'
  }
  else if(new_model.toLowerCase() == 'aws-bedrock-titan') {
    model = 'aws-bedrock-titan'
  }
  else if(new_model.toLowerCase() == 'aws-bedrock-claude-35-sonnet-gov') {
    model = 'aws-bedrock-claude-35-sonnet-gov'
  }
  else if(new_model.toLowerCase() == 'google-gemini-pro') {
    model = 'google-gemini-pro'
  }
  else if(new_model.toLowerCase() == 'mistral-large') {
    model = 'mistral-large'
  }
  else if(new_model.toLowerCase() == 'gpt4') {
    model = 'gpt4'
    history_count = 16;
  }
  else if(new_model.toLowerCase() == 'gpt4-gov') {
    model = 'gpt4-gov'
    history_count = 16;
  }
  else if(new_model == 'gpt4-32k') {
    model = 'gpt4-32k'
    history_count = 22;
  }
  else if(new_model == 'gpt4-vision') {
    model = 'gpt4-vision'
    history_count = 50;
  }
  else if(new_model == 'gpt-4o') {
    model = 'gpt-4o'
    history_count = 50;
  }
  else if(new_model == 'gpt-4o-mini') {
    model = 'gpt-4o-mini'
    history_count = 50;
  }
  else if(new_model == 'gpt-o1-mini') {
    model = 'gpt-o1-mini'
    history_count = 50;
  }
  else if(new_model == 'gpt-o1') {
    model = 'gpt-o1'
    history_count = 50;
  }
else if(new_model == 'gpt-4o-gov') {
    model = 'gpt-4o-gov'
    history_count = 50;
  }
  else if(new_model == 'dall-e-2') {
    model = 'dall-e-2'
    history_count = 1;
  }
  else if(new_model == 'dall-e-3') {
    model = 'dall-e-3'
    history_count = 1;
  }
  else if(new_model == 'gpt35-16k') {
    model = 'gpt35-16k'
    history_count = 18;
  }

  return {model: model, history_count: history_count}
}

async function getTokenizerR(string) {

  var tokens_count = 0;
  var token = getToken()
  if(token === false) {
    return 0;
  }

  if (string == null || string == '') {
    return 0;
  }

  var model = 'openai_gpt';
  try {
    const response = await fetch(default_data.default_chat_service_url + '/tokenizer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },        
      body: JSON.stringify({
        content: string,
        model: model
      })
    })
    const data = await response.json();
    if(data != null && data.status != 200) {            
      console.log(data)
    }
    else {
      var cur_tokens = parseInt(data.response);
      tokens_count = cur_tokens
    }
  }
  catch {
    tokens_count = 0;
  }
  return tokens_count
} 


export { proposeModelCount, getTokenWindowR, isCUICapableR, getModelInfoR, getTokenizerR }