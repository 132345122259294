import { Paper, Button, Box, Card, CardContent, CardHeader } from '@mui/material'
import * as default_data from '../../../config'
import { plans } from '../../../Data/plans'

export const PaidFeatures = ({paid, cur_user}) => {
  return (
    <Paper sx={{ padding: 2, mt: 2 }}>
      <div>
        <h3 className="modal_header">Ask Sage Plans</h3>
        <div className="scroller">
          <Box display={{ xs: 'none', md: 'block' }}>
            <table className="table-border" style={{background:"transparent", width: "auto", margin: "auto"}}>
              <thead>
                <tr><th>Plan cost</th><th>Ask Sage Inference Tokens</th><th>Ask Sage Training Tokens</th><th>Paid Features</th></tr>
              </thead>
              <tbody>
                {plans.map((plan, index) => (
                  <tr key={index}>
                    <td>{plan.cost}</td>
                    <td>
                      {plan.inferenceTokens}
                      {plan.interfaceDetails && <span><br/>{plan.interfaceDetails}</span>}
                    </td>
                    <td>
                      {plan.trainingTokens}
                      {plan.trainingDetails && <span><br/>{plan.trainingDetails}</span>}
                    </td>
                    <td>{plan.features}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          <Box display={{ xs: 'block', md: 'none' }}>
            {plans.map((plan, index) => (
              <Card key={index} sx={{border: '1px solid #ccc', borderRadius: '5px', marginBottom: 2}}>
                <CardHeader title={plan.cost} />
                <CardContent>
                  <strong>Ask Sage Inference Tokens:</strong> {plan.inferenceTokens}
                  {plan.interfaceDetails && <span><br/>{plan.interfaceDetails}</span>}<br />
                  <strong>Ask Sage Training Tokens:</strong> {plan.trainingTokens}
                  {plan.trainingDetails && <span><br/>{plan.trainingDetails}</span>}<br />
                  <strong>Paid Features:</strong> {plan.features}
                </CardContent>
              </Card>
            ))}
          </Box>
          <br />
          <div style={{textAlign: "center"}}>*Enterprise purchases: you can buy Ask Sage tokens in bulk and assign them to your users (with 500K tokens minimum per user) via our Administration User Interface, without paying a fee per user. Starts at $90/mo for 2M Ask Sage tokens per month! Reach out to us for more information.<br /><br />Ask Sage can be deployed on your Cloud enclaves and/or on-premise environments.</div>
          { (paid != true && default_data.default_customer_tenant !== true) || (cur_user && cur_user.plan_type && cur_user.plan_type === 'trial') ?
            <div style={{textAlign: 'center', margin: 'auto'}}>
              <Button variant='contained' href="/payment">Subscribe now for more features & tokens!</Button>
            </div>
            : ''
          }
          <br />
          <h3 className="modal_header">Exclusive Paid Features</h3>
          <strong>Ask Sage API access</strong><br />
          <strong>Live+ Feature</strong>: like "Live", it leverages Bing to search real-time result but Live+ loads the first 2 results content for more accurate answers! Warning, this will use more tokens.<br /><br />
          <strong>More models</strong>:<br />
          <ul>
            <li><strong>GPT-3.5-16K</strong>: 40 pages context window!<br /></li>
            <li><strong>GPT-4-32K</strong>: 80 pages context window!<br /></li>
            <li><strong>GPT-4-Vision</strong>: 300 pages context window with image recognition!<br /></li>
            <li><strong>GPT-4o</strong>: 300 pages context window!<br /></li>
            <li><strong>GPT-4o-mini</strong>: 300 pages context window!<br /></li>
            <li><strong>GPT-o1</strong>: 300 pages context window!<br /></li>
            <li><strong>GPT-o1-mini</strong>: 300 pages context window!<br /></li>
            <li><strong>DALL-E (v2)</strong>: generate images.<br /></li>
            <li><strong>DALL-E (v3)</strong>: generate images.<br /></li>
            <li><strong>TTS/TTS-HD</strong>: text to speech.<br /></li>
            <li><strong>Whisper</strong>: speech to text.<br /></li>
            <li><strong>Claude 2 (100K)</strong>: 250 pages context window! (not available for CUI)<br /></li>
            <li><strong>Claude 3 (200K)</strong>: 500 pages context window! (not available for CUI)<br /></li>
            <li><strong>AWS Bedrock Titan</strong> AWS Bedrock Titan model<br /> </li>
            <li><strong>AWS Gov Bedrock Claude 3.5 Sonnet</strong> AWS Gov Bedrock Claude 3.5 Sonnet model<br /> </li>
            <li><strong>Google Bison</strong> (Google Bard's model)<br /> </li>
            <li><strong>Google Gemini Pro</strong><br /> </li>
            <li><strong>Mistral Large</strong><br /> </li>
          </ul>
          <strong>Exclusive Plugins and Agents</strong> (over 15) such as RFP answering, Git repository analysis and auditor, Summarization, Ingestion etc.<br />
          <strong>Data Connectors</strong> Postgres, Elastic connectors, and more.<br />
          <strong>File ingestor</strong> (zip, pdf, xlsx, pptx, docx, ppt, csv, cc, sql, cs, hh, c, php, js, py, html, xml, msg, odt, epub, eml, rtf, txt, doc, json, md, jpeg, jpg, png, tsv, yaml, yml, java, rb, sh, bat, ps1) (50MB)<br />
          <strong>Speech to text ingestor (Whisper)</strong> (mp3, mp4, mpeg, mpga, m4a, wav, webm) (500MB max)<br />
          <strong>Web ingestor</strong><br />
          <strong>Python code sandbox</strong><br />
          <strong>Custom introduction prompt</strong>: allows to have a prompt context per user so Ask Sage tracks who you are and your context<br />
          <br />
          <strong>Customizations available for additional cost:</strong><br />
          <ul>
            <li>Add & Fine-tune your own LLMs<br /></li>
            <li>Create custom plugins<br /></li>
            <li>Integrate with your data lake/API/data warehouse.<br /></li>
          </ul>
        </div>
      </div>
    </Paper>
  )
}