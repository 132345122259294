import { createTheme } from '@mui/material';

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

// Base theme with common properties (ensure 'mode' is not set here)
const baseTheme = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: "10px",
            padding: 0,
            margin: 0,
          },
          "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
            background: 'lightgray',
            borderRadius: '20px',
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            background: '#888',
            borderRadius: '20px',
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            background: '#555',
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      mobile: 750,
      md: 900,
      tablet: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    tooltip: {
      backgroundColor: 'white',
      color: 'black',
      iconColor: '#aaaaaa',
      borderColor: '#D5BB10',
    },
    appBackground: '#080429',
    chatBackground: '#1b212d',
    chatInputBackground: '#282c34',
    textColorSecondary: '#e9ecef',
    buttonColor: createColor('#ffffff'),
    chatInput: {
      backgroundColor: '#404954',
    },
    dropdowns: {
      backgroundColor: '#404954',
      color: '#e9ecef',
      border: '1px solid #929396',
    },
    card: {
      backgroundColor: '#121212',
      backgroundColorLight: '#444444',
      border: '1px solid #bbbbbb',
      hover: {
        boxShadow: '0 0 0 3px #1976d2',
        backgroundColor: 'black',
        border: '1px solid #121212',
      },
    },
    table: {
      groupBackground: '#f8f8f8',
      header: {
        backgroundColor: '#000000',
        color: '#e9ecef',
      },
      body: {
        backgroundColor: '#222222',
        backgroundColorLight: '#444444',
        color: '#3d3e40',
      },
    },
    sidebar: {
      item: {
        backgroundColor: '#1b212d',
        hover: {
          backgroundColor: '#282c34',
        },
        selected: {
          backgroundColor: '#e0e0e0',
          color: '#333333',
        },
      },
    },
  },
};

// Now, create the themes by merging properties
export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: 'dark',
    appBackground: '#080429',
    chatBackground: '#1b212d',
    chatInputBackground: '#282c34',
    textColorSecondary: '#e9ecef',
    buttonColor: createColor('#ffffff'),
    chatInput: {
      backgroundColor: '#404954',
    },
    dropdowns: {
      backgroundColor: '#404954',
      color: '#e9ecef',
      border: '1px solid #929396',
    },
    card: {
      backgroundColor: '#121212',
      backgroundColorLight: '#444444',
      border: '1px solid #bbbbbb',
      hover: {
        boxShadow: '0 0 0 3px #1976d2',
        backgroundColor: 'black',
        border: '1px solid #121212',
      },
    },
    table: {
      groupBackground: '#525252',
      header: {
        backgroundColor: '#000000',
        color: '#e9ecef',
      },
      body: {
        backgroundColor: '#222222',
        backgroundColorLight: '#444444',
        color: '#3d3e40',
      },
    },
    sidebar: {
      item: {
        backgroundColor: '#1b212d',
        hover: {
          backgroundColor: '#282c34',
        },
        selected: {
          backgroundColor: '#e0e0e0',
          color: '#333333',
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: 'light',
    appBackground: '#e9ecef',
    chatBackground: '#ffffff',
    chatInputBackground: '#e9ecef',
    textColorSecondary: '#3d3e40',
    buttonColor: createColor('#000000'),
    chatInput: {
      backgroundColor: '#f0f0f0',
    },
    dropdowns: {
      backgroundColor: '#f0f0f0',
      color: '#3d3e40',
      border: '1px solid #3d3e40',
    },
    card: {
      backgroundColor: '#f8f8f8',
      backgroundColorLight: '#e9ecef',
      border: '1px solid #999999',
      hover: {
        boxShadow: '0 0 0 3px #1976d2',
        backgroundColor: '#fafafa',
        border: '1px solid #f8f8f8',
      },
    },
    table: {
      groupBackground: '#f1f1f1',      
      header: {
        backgroundColor: '#c7c7c7',
        color: '#3d3e40',
      },
      body: {
        backgroundColor: '#ffffff',
        backgroundColorLight: '#f0f0f0',
        color: '#3d3e40',
      },
    },
    sidebar: {
      item: {
        backgroundColor: '#ffffff',
        hover: {
          backgroundColor: '#bebfd4',
        },
        selected: {
          backgroundColor: '#bfbfbf',
          color: '#333333',
        },
      },
    },
  },
});

export const realDarkTheme = createTheme({
  ...baseTheme,
  components: {
    ...baseTheme.components,
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
            background: "transparent",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
          },
        },
      },
    },
  },
  palette: {
    ...baseTheme.palette,
    mode: 'dark',
    appBackground: '#121212',
    chatBackground: '#282828',
    chatInputBackground: '#121212',
    textColorSecondary: '#e9ecef',
    buttonColor: createColor('#ffffff'),
    chatInput: {
      backgroundColor: '#121212',
    },
    dropdowns: {
      backgroundColor: '#404954',
      color: '#e9ecef',
      border: '1px solid #929396',
    },
    card: {
      backgroundColor: '#121212',
      backgroundColorLight: '#444444',
      border: '1px solid #bbbbbb',
      hover: {
        boxShadow: '0 0 0 3px #1976d2',
        backgroundColor: 'black',
        border: '1px solid #121212',
      },
    },
    table: {
      groupBackground: '#525252',
      header: {
        backgroundColor: '#000000',
        color: '#e9ecef',
      },
      body: {
        backgroundColor: '#222222',
        backgroundColorLight: '#444444',
        color: '#3d3e40',
      },
    },
    sidebar: {
      item: {
        backgroundColor: '#1b212d',
        hover: {
          backgroundColor: '#282c34',
        },
        selected: {
          backgroundColor: '#e0e0e0',
          color: '#333333',
        },
      },
    },
  },
});
