import { Box } from "@mui/material";
import { FaMagnifyingGlass } from "react-icons/fa6";

export const NoResults = ({message}) => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column', width:'100%', my: 10}}>
      <FaMagnifyingGlass style={{fontSize: '100px', color: 'gray'}} />
      <p style={{color: 'gray', fontWeight: 700}}>{message}</p>
    </Box>
  );
}