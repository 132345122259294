import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as default_data from './config.js';
import { ThemeProvider } from '@mui/material';
import CssBaseline from "@mui/material/CssBaseline";
import { darkTheme, lightTheme, realDarkTheme } from './themes';
import { GoogleOAuthProvider as GoogleContextProvider } from './Contexts/GoogleContext';
import { UserSettingsProvider, useUserSettings } from './Contexts/UserPreferenceContext.js';

// Move the function outside the RootComponent
export async function loadGoogleClientId() {
  try {
    if (default_data.default_has_google_login_enabled === false) {
      return;
    }
    const response = await fetch(default_data.default_chat_service_url + '/get-google-info', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({})
    });

    if (response.ok) {
      const data = await response.json();
      if (data.status !== 200) {
        if (data.status === 404) {
          return null;
        }
        throw new Error('Invalid tokens');
      } else {
        if (data.response === null) {
          return null;
        }
        return data.response;
      }
    } else {
      throw new Error('An error occurred.');
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

function RootComponent() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [clientId, setClientId] = useState(null);
  const { state } = useUserSettings(); // Access the user settings context

  const getTheme = () => {
    if (state.theme === 'light') {
      return lightTheme;
    } else if (state.theme === 'dark') {
      return darkTheme;
    } else {
      return realDarkTheme;
    }
  }

  useEffect(() => {
    loadGoogleClientId().then((response) => {
      setIsLoaded(true);
      if (response === null || response === undefined) {
        return;
      }
      setClientId(response['client_id']);
    });
  }, []);

  return (
    isLoaded === true ?
      clientId !== null && clientId !== false ?
        <GoogleContextProvider clientId={clientId}>
          <GoogleOAuthProvider clientId={clientId}>
            <ThemeProvider theme={getTheme()}>
              <CssBaseline />
              <React.StrictMode>
                <App />
              </React.StrictMode>
            </ThemeProvider>
          </GoogleOAuthProvider>
        </GoogleContextProvider>
        :
        <GoogleContextProvider clientId={clientId}>
          <React.StrictMode>
            <ThemeProvider theme={getTheme()}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </React.StrictMode>
        </GoogleContextProvider>
      : ''
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
// Render the new component
root.render(
  <UserSettingsProvider>
    <RootComponent />
  </UserSettingsProvider>
);