import React, { useState } from "react";
import { Alert, Box, Button, Card, MenuItem, Select, Skeleton, Stack, Switch, Typography, useTheme } from "@mui/material";
import { SelectDataset } from "../ChatBox";
import { IoHardwareChip, IoKeySharp } from "react-icons/io5";
import { MdFingerprint, MdPeople } from "react-icons/md";
import { setTheme, useUserSettings } from "../../Contexts/UserPreferenceContext";
import { Link } from "react-router-dom";
import * as default_data from '../../config.js'
import { getToken, isAdminLoggedIn } from "../../AuthService.js";
import { SelectModel } from "../Components/SelectModel.js";

const SettingsCard = ({ title, cardColor, children, loading, loadingCardHeight }) => {
  if (loading) {
    return (
      <Card sx={{ p: 2, borderRadius: 2, backgroundColor: cardColor, height: loadingCardHeight }}>
        <Stack direction={'column'} spacing={1}>
          <Skeleton variant="text" width={'100%'} height={40} />
          <Skeleton variant="text" width={'100%'} height={40} />
          <Skeleton variant="text" width={'100%'} height={40} />
        </Stack>
      </Card>
    );
  }

  return (
    <Card sx={{ p: 2, borderRadius: 2, backgroundColor: cardColor }}>
      {title && <Typography variant='h6' fontSize={'16px'} mb={1} fontWeight={600}>
        {title}
      </Typography>}
      {children}
    </Card>
  );
};

export const CustomizationTab = ({
  onClose, 
  personas, 
  datasets,
  setShowAPIKeysList,
  user,
  loading,
  updateInfo,
}) => {
  const { state, dispatch } = useUserSettings(); // Access the dispatch function
  const [CACErrorMessage, setCACErrorMessage] = useState(undefined);
  const [CACSuccessMessage, setCACSuccessMessage] = useState(undefined);
  const theme = useTheme();
  
  const mfaNotEnabled = !loading && (user.mfa_secret === '' || user.mfa_secret == null || user.mfa_secret.substring(0, 2) === 'T-');

  const onChange = (e) => {
    dispatch({ type: 'SET_DEFAULT_DATASETS', payload: e }); // Dispatch the action to update the default datasets
  };

  function registerCAC() {
    var token = getToken();
    if(user == false || user == null) {
      window.location = '/login';
      return;
    }

    fetch(default_data.default_user_service_url + '/register-cac', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },        
      body: JSON.stringify({
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('An error occurred while sending chats.');
      })
      .then(data => {
        if(data.status != 200) {
          setCACErrorMessage('CAC/PIV not found - if you are getting this error, it is very likely that your local IT proxy is blocking CAC/PIV passthrough in which case you would need to open a local IT ticket with your IT support to whitelist *.asksage.ai for CAC/PIV passthrough. If this does not help, please reach out to us at support@asksage.ai')
          setCACSuccessMessage('')
        }
        else {          
          setCACErrorMessage('')
          setCACSuccessMessage('CAC/PIV successfully added.')
          updateInfo()
        }
      })
      .catch(error => {

      });    
  }

  return (
    <Stack direction={'column'} spacing={2} padding={0}>
      <SettingsCard title={'General'} cardColor={theme.palette.card.backgroundColorLight}>
        <Stack direction={'column'} px={0} spacing={1}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1} spacing={0}>
            <Typography variant='body1'>
              Theme
            </Typography>
            <Select
              value={state.theme}
              onChange={(e) => {
                dispatch(setTheme(e.target.value)); // Dispatch the action to update the theme
              }}
              size="small"
              sx={{ 
                width: 150,
                backgroundColor: theme.palette.dropdowns.backgroundColor,
                '&.MuiInputBase-root': {
                  fontSize: '0.875rem',
                },
              }}
            >
              <MenuItem key={'dark'} value={'dark'} sx={{fontSize: 13}}>Dark</MenuItem>
              <MenuItem key={'light'} value={'light'} sx={{fontSize: 13}}>Light</MenuItem>
              <MenuItem key={'modernDark'} value={'realDark'} sx={{fontSize: 13}}>Modern Dark</MenuItem>
            </Select>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1}>
            <Typography variant='body1'>
              Show All Prompt Follow-up Recommendations 
            </Typography>
            <Switch
              checked={state.showAllRecommendations}
              onChange={
                () => {
                  dispatch({ type: 'SET_SHOW_ALL_RECOMMENDATIONS', payload: !state.showAllRecommendations }); // Dispatch the action to update the show all recommendations
                }
              }
              size="small"
            />
          </Stack>
        </Stack>
      </SettingsCard>
      <SettingsCard title={'Chat Mode'} cardColor={theme.palette.card.backgroundColorLight}>
        <Stack direction={'column'} px={0} spacing={1}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1}>
            <Typography variant='body1'>
              Modern Chat
            </Typography>
            <Switch
              checked={state.useModernChat}
              onChange={() => {
                dispatch({ type: 'SET_USE_MODERN_CHAT', payload: !state.useModernChat }); // Dispatch the action to update the modern chat input
              }}
              size="small"
            />
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1}>
            <Typography variant='body1'>
              Advanced Chat Fields
            </Typography>
            <Switch
              checked={state.useAdvanceFields}
              onChange={() => {
                dispatch({ type: 'SET_USE_ADVANCE_FIELDS', payload: !state.useAdvanceFields }); // Dispatch the action to update the advanced user fields
              }}
              size="small"
            />
          </Stack>
        </Stack>
      </SettingsCard>
      <SettingsCard title={'Prompt Settings'} cardColor={theme.palette.card.backgroundColorLight} loading={!user} loadingCardHeight={200}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1} pb={1}>
          <Typography variant='body1'>
            Dataset
          </Typography>
          <Box sx={{ width: 200 }}>
            <SelectDataset 
              value={state.defaultDatasets}
              datasets={datasets}
              onChange={onChange}
              maxMenuHeight={100}
            />
          </Box>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1} pb={1}>
          <Typography variant='body1'>
            Model
          </Typography>
          <SelectModel
            value={state.defaultModel}
            cur_user={user}
            onChange={(e) => {
              dispatch({ type: 'SET_DEFAULT_MODEL', payload: e.target.value }); // Dispatch the action to update the default model
            }}
            paid={user ? user.paid : false}
            sx={{ 
              width: 200,
              height: '40px !important',
              '&.MuiInputBase-root': {
                fontSize: '0.875rem',
              },
            }}
          />
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} pl={1}>
          <Typography variant='body1'>
            Personality
          </Typography>
          <Select
            value={state.defaultPersonality}
            onChange={(e) => {
              dispatch({ type: 'SET_DEFAULT_PERSONALITY', payload: e.target.value }); // Dispatch the action to update the default personality
            }}
            size="small"
            sx={{ 
              width: 200,
              backgroundColor: theme.palette.dropdowns.backgroundColor,
              '&.MuiInputBase-root': {
                fontSize: '0.875rem',
              },
            }}
            MenuProps={{
              style: {
                maxHeight: 500,          
              }
            }}
          >
            {personas.map((p, index) => (
              <MenuItem key={index} value={p.id} sx={{fontSize: 13}}>{p.name}</MenuItem>
            ))}
          </Select>
        </Stack>
      </SettingsCard>
      <SettingsCard cardColor={theme.palette.card.backgroundColorLight}>
        <Stack direction={'column'} spacing={1}>
          <Button
            color="primary"
            component={Link}
            to={'/mfa-setup'}
            target="_blank"
            variant="contained"
            disabled={!mfaNotEnabled}
            fullWidth
            startIcon={<MdFingerprint />}
            sx={{ 
              textTransform: 'none', 
              '&.Mui-disabled': {
                color: 'black',
              }
             }}
          >
            { 
              mfaNotEnabled ? 
              "Setup multi factor authentication (MFA)" : 
              "MFA is already setup."
            }
          </Button>
          {CACErrorMessage && (
            <Alert severity="error" onClose={()=>{setCACErrorMessage(undefined)}} size={"small"}>
              {CACErrorMessage}
            </Alert>
          )}
          {CACSuccessMessage && (
            <Alert severity="success" onClose={()=>{setCACSuccessMessage(undefined)}} size={"small"}>
              {CACSuccessMessage}
            </Alert>
          )}
          { !default_data.default_hide_cac && (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disabled={loading || user.cac_found}
              startIcon={<IoHardwareChip />}
              onClick={registerCAC}
              sx={{ 
                textTransform: 'none', 
                '&.Mui-disabled': {
                  color: 'black',
                }
               }}
            >
              {loading || user.cac_found ? 
                "CAC registered successfully." : 
                <Box>
                  Register CAC/PIV to your account
                  <br />
                  (CAC must be in the reader to work)
                </Box>
              }
            </Button>
          )}
          {isAdminLoggedIn() === true && (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              startIcon={<MdPeople />}
              component={Link}
              to={'/admin/get-users'}
              target="_blank"
              sx={{ textTransform: 'none' }}
            >
              Manage your Organization
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            fullWidth
            startIcon={<IoKeySharp />}
            onClick={() => {
              onClose();
              setShowAPIKeysList(1);
            }}
          >
            Manage your API keys
          </Button>
        </Stack>
      </SettingsCard>
    </Stack>
  );
};