import { DialogContent } from "@mui/material";
import { useEffect, useRef } from "react";

export const ScrollableDialogContent = ({ children, sx, modalIsOpen }) => {
  const contentRef = useRef();

  useEffect(() => {
    if (modalIsOpen) {
      const timer = setTimeout(() => {
        if (contentRef.current) {
          contentRef.current.focus();
        }
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [modalIsOpen]);

  return (
    <DialogContent ref={contentRef} sx={{ ':focus': {outline: 'none'}, ...sx}} tabIndex={1}>
      {children}
    </DialogContent>
  );
};
