import React from 'react';
import { Box, Chip, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material';
import { FileManagementTable } from './FileManagementTable';
import { FiChevronDown, FiChevronRight, FiShare2, FiTrash } from 'react-icons/fi'

export const GroupContainer = ({ 
  index,
  group,
  openFiles,
  setOpenFiles,
  openDatasets,
  setOpenDatasets,
  selectedFiles,
  setSelectedFiles,
  setDataSetToDelete,
  setDatasetToShare,
  refetch,
}) => {
  const theme = useTheme();
  const open = openDatasets.includes(group.source);

  const setOpen = () => {
    setOpenDatasets(
      openDatasets.includes(group.source) ? openDatasets.filter((dataset) => dataset !== group.source) : [...openDatasets, group.source]
    );
  }

  const extractDatasetName = (input) => {
    const pattern = /user_custom_\d+_([^_]+)_content/;
    const match = input.match(pattern);
    if (match && match[1]) {
        return match[1];
    } else {
        return '';
    }
  }

  // look through all of the files and if one is cui then return true
  const hasCUI = group.files.some((file) => file.CUI);

  return (
    <Paper key={index} sx={{ padding: 1, marginBottom: 2, backgroundColor: theme.palette.table.groupBackground, boxShadow: 'none' }}>
      <Stack direction="row" spacing={2} alignItems="center">
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
          disabled={group.files.length === 0}
        >
          {open ? <FiChevronDown /> : <FiChevronRight />}
        </IconButton>
        <Typography size={16} fontWeight={'bold'}>{extractDatasetName(group.source)}</Typography>
        {hasCUI && <Chip label="CUI" color="success" size='small'/>}
        <Typography fontSize={14} >{`${group.files.length} Files`}</Typography>
        <Stack direction="row" spacing={0.5}>
          <IconButton
            aria-label='delete'
            size='small'
            onClick={()=> {
              setDataSetToDelete(group.source);
            }}
          >
            <FiTrash/>
          </IconButton>
          <IconButton
            aria-label='share dataset'
            size='small'
            onClick={()=> {
              setDatasetToShare(group.source);
            }}
          >
            <FiShare2/>
          </IconButton>
        </Stack>
      </Stack>
      {open && (
        <Box sx={{ padding: 1 }}>
          <FileManagementTable
            data={group.files}
            fetching={false}
            groupDatasets={true}
            openFiles={openFiles}
            setOpenFiles={setOpenFiles}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            refetch={refetch}
          />
        </Box>
      )}
    </Paper>
  );
}
