import React from 'react';
import { Card, Grid, Stack, Typography, useTheme } from '@mui/material';
import { FiInfo } from 'react-icons/fi';
import { IoWarning } from 'react-icons/io5';
import { MdError } from 'react-icons/md';

export const TokenCard = ({
  tokensUsed,
  tokensTotal,
  title,
  loading
}) => {
  const theme = useTheme();
  const percentUsed = (tokensUsed / tokensTotal) * 100;

  const tokensRemaining = tokensTotal - tokensUsed;

  const getAlertColor= () => {
    if (percentUsed < 75) {
      return theme.palette.primary.main;
    } else if (percentUsed < 90) {
      return theme.palette.warning.main;
    } else {
      return theme.palette.error.main;
    }
  }

  const getAlertIcon = () => {  
    if (percentUsed < 75) {
      return <FiInfo size={24} color={theme.palette.primary.main} />;
    } else if (percentUsed < 90) {
      return <IoWarning size={24} color={theme.palette.warning.main} />;
    } else {
      return <MdError size={24} color={theme.palette.error.main} />;
    }
  }

  return (
    <Card sx={{ border: `2px solid ${getAlertColor()}`, borderRadius: 2}}>
      {!loading && (
        <Grid container spacing={1} px={2} py={1}>
          <Grid item xs={1.5}>
            {getAlertIcon()} 
          </Grid>
          <Grid item xs={8.5}>
            <Typography variant={'h6'} fontSize={18}>
              {title}
            </Typography>
            <Grid container columnSpacing={1}>
              <Grid item xs={6}>
                <Typography variant={'body1'} textAlign={'end'} fontSize={16}>
                  {tokensUsed.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={6} alignContent={'center'}>
                <Typography variant={'body1'} fontSize={16} color={theme.palette.text.secondary}>
                  used
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'body1'} textAlign={'end'} fontSize={16}>
                  {tokensRemaining.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={6} alignContent={'center'}>
                <Typography variant={'body1'} fontSize={16} color={theme.palette.text.secondary}>
                  remaining
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'body1'} textAlign={'end'} fontSize={16}>
                  {tokensTotal.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={6} alignContent={'center'}>
                <Typography variant={'body1'} fontSize={16} color={theme.palette.text.secondary}>
                  total
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} width={'100%'}>
            <Typography variant={'h5'} textAlign={'end'} color={getAlertColor()}>
              {percentUsed.toFixed(0)}%
            </Typography>
            <Typography variant={'body1'} textAlign={'end'}>
              used
            </Typography>
          </Grid>
        </Grid>
      )}
    </Card>
  )
}

