import { FormControl, MenuItem, Select, Button, Box, useTheme } from '@mui/material'
import { AiFillCaretDown } from "react-icons/ai";
// Select Persona acts a button showPersonaList is passed as a prop
export const SelectPersona = ({showPersonaList, value, personas, onChange, useAll}) => {    
  const theme = useTheme()
  const options = personas.map((obj) => {
    return (
      <MenuItem key={obj['label']} value={obj['label']} sx={{fontSize: 13}}>
        {obj['name']}
      </MenuItem>
    );
  });

  if (useAll != null && useAll === 'true') {
    options.unshift(
      <MenuItem key="all" value="all" sx={{fontSize: 13}}>
        All Personas
      </MenuItem>
    );
  }

  return (
      <FormControl>
        {showPersonaList !== null && showPersonaList !== undefined ?
          <Button
            onClick={showPersonaList} 
            variant="outlined"
            size='small'
            sx={{
              backgroundColor: theme.palette.dropdowns.backgroundColor,
              color: theme.palette.text.primary,
              borderColor: '#5c5c5c',
              textTransform: 'none',
              '&:hover': {
                borderColor: '#d8d8d8',
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {
                options.filter((obj) => obj.props.value === value || obj.props.value === parseInt(value)).length > 0 ? 
                options.filter((obj) => obj.props.value === value || obj.props.value === parseInt(value))[0].props.children
                :
                'Select Persona'}
              <AiFillCaretDown style={{marginLeft: 4}} />
            </Box>
          </Button>
          :
          <Select value={value} onChange={onChange} className="select-menu">
            {options}
          </Select>
        }
      </FormControl>
  );
};