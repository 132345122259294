import { getUser } from '../../AuthService';
import { processDataset } from '../ChatBox'
import { Select, MenuItem } from '@mui/material'

export const SelectSingleDataset = ({value, datasets, onChange, noAllNone}) => {    
  const user = getUser();
  const userId = user.id;
  const defaultOption = `user_custom_${userId}_content`;

  const options = datasets
    .filter(obj => {
      if (obj === `user_custom_${userId}_content`) {
        return false;
      }
      if (noAllNone === 'true') {
        return obj.indexOf(`user_custom_${userId}_`) !== -1;
      }
      return true;
    })
    .map(obj => {
      const label = processDataset(obj);
      return (
        <MenuItem key={obj} value={obj} sx={{fontSize: 13}}>
          {label}
        </MenuItem>
      );
    });

  if (noAllNone !== 'true') {
    options.unshift(
      <MenuItem key="all" value="all" sx={{fontSize: 13}}>All</MenuItem>,
      <MenuItem key="none" value="none" sx={{fontSize: 13}}>None</MenuItem>
    );
  }

  options.unshift(
    <MenuItem key={defaultOption} value={defaultOption} sx={{fontSize: 13}}>Default</MenuItem>
  );

  return (
    <Select 
      name="dataset" 
      value={value || defaultOption} 
      onChange={onChange} 
      size='small'
    >
      {options}
    </Select>
  );
};
