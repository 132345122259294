export const commands = [
  {
    command: '/add-dataset',
    description: 'Creates a new dataset',
  },
  {
    command: '/delete-dataset',
    description: 'Deletes a dataset',
  },
  {
    command: '/stats-dataset',
    description: 'Get stats from a dataset (token count, character count, and files ingested)',
  },
  {
    command: '/assign-dataset',
    description: 'Assign a dataset to an email',
  },
  {
    command: '/deassign-dataset',
    description: 'Dessign a dataset to an email',
  },
  {
    command: '/get-datasets',
    description: 'Lists your datasets',
  },
  {
    command: '/train',
    description: 'Train text/plain content into dataset',
  },
  {
    command: '/get',
    description: 'Retrieves matching content from ALL your custom datasets in vector database',
  },
  {
    command: '/get-results-dataset',
    description: 'Retrieves all results from a specific dataset',
  },
  {
    command: '/get-files-dataset',
    description: 'Retrieves the names of files ingested in a specific dataset',
  },
  {
    command: '/delete',
    description: 'Deletes a training from your custom datasets in vector database',
  },
  {
    command: '/new',
    description: 'Creates a new chat',
  },
  {
    command: '/clear',
    description: 'Clears the current chat window',
  },
  {
    command: '/yes',
    description: 'To be used when asked by the bot in a plugin to confirm your approval',
  },
  {
    command: '/no',
    description: 'To be used when asked by the bot in a plugin to confirm your refusal',
  },
  {
    command: '/skip',
    description: 'To be used when asked by the bot in a plugin to skip a step',
  },
  {
    command: '/stop',
    description: 'To be used when asked by the bot in a plugin to stop the current work',
  },
  {
    command: '/add-secret',
    description: 'Creates a secret stored in our vault',
  },
  {
    command: '/update-secret',
    description: 'Updates a secret stored in our vault',
  },
  {
    command: '/delete-secret',
    description: 'Deletes a secret stored in our vault',
  },
  {
    command: '/get-secrets',
    description: 'Get your list of secrets stored from our vault',
  },  
];