import React, { useState } from 'react';
import { Drawer, Stack, Typography, IconButton, Tabs, Tab } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { CustomizationTab } from './CustomizationTab';
import { TokensTab } from './TokensTab';
import { useFetchMonthlyTokens, useFetchTeachTokens, useFetchMaxTokens, useFetchUser } from "./query"

export const SettingsDrawer = ({ 
  open,
  onClose,
  personas,
  datasets,
  setShowAPIKeysList,
  settingsTabValue,
}) => {  
  const [tab, setTab] = useState(settingsTabValue || 'customization');

  const { 
    data: inferenceTokens, 
    loading: loadingInferenceTokens, 
    refetch: refetchInferenceTokens
  } = useFetchMonthlyTokens();

  const { 
    data: teachTokens, 
    loading: loadingTeachTokens, 
    refetch: refetchTeachTokens
  } = useFetchTeachTokens();

  const {
    maxInferenceTokens,
    maxTeachTokens,
    loading: loadingMaxTokens,
    refetch: refetchMaxTokens
  }= useFetchMaxTokens();

  const {user, loading, refetch: updateInfo} = useFetchUser();

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Stack direction={'column'} spacing={0} sx={{ padding: 1, width: 350, height: '100%' }}>
        <Stack direction={'row'} spacing={1} sx={{ paddingBottom: 0, alignItems: 'center', position: 'sticky', top: 0, zIndex: 1 }}>
          <IconButton onClick={onClose}>
            <IoClose />
          </IconButton>
          <Typography variant='h6'>
            Settings
          </Typography>
        </Stack>
        <Tabs value={tab} onChange={(e, v) => setTab(v)} sx={{ position: 'sticky', top: 48, zIndex: 1 }}>
          <Tab label="Customization" value={"customization"} />
          <Tab label="Tokens" value={"tokens"} />
        </Tabs>
        <Stack direction={'column'} spacing={0} sx={{ p: 0, pt:1, overflowY: 'auto', flexGrow: 1 }}>
          <Stack direction={'column'} spacing={1}>
            {tab === 'customization' && (
              <CustomizationTab 
                onClose={onClose}
                personas={personas}
                datasets={datasets}
                setShowAPIKeysList={setShowAPIKeysList}
                user={user}
                loading={loading}
                refetch={updateInfo}
              />
            )}
            {tab === 'tokens' && (
              <TokensTab 
                inferenceTokens={inferenceTokens}
                teachTokens={teachTokens}
                maxInferenceTokens={maxInferenceTokens}
                maxTeachTokens={maxTeachTokens}
                loadingInferenceTokens={loadingInferenceTokens}
                loadingTeachTokens={loadingTeachTokens}
                loadingMaxTokens={loadingMaxTokens}
                refetchInferenceTokens={refetchInferenceTokens}
                refetchTeachTokens={refetchTeachTokens}
                refetchMaxTokens={refetchMaxTokens}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
};