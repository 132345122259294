import { Box, Button, Card, Stack, Typography, useTheme } from "@mui/material"
import { useEffect, useMemo, useState } from "react"

import { TokenCard } from "./TokenCard";
import { getUser } from "../../AuthService";
import { Link } from "react-router-dom";


export const TokensTab = ({
  inferenceTokens,
  teachTokens,
  maxInferenceTokens,
  maxTeachTokens,
  loadingInferenceTokens,
  loadingTeachTokens,
  loadingMaxTokens,
  refetchInferenceTokens,
  refetchTeachTokens,
  refetchMaxTokens,
}) => {
  const user = getUser();
  const theme = useTheme();

  const getFirstDayOfNextMonth = () => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    date.setDate(1);
    return date;
  }

  const isLowOnTokens = useMemo(() => {
    // return true if the user has used more then 80% of their tokens
    if (inferenceTokens && teachTokens && maxInferenceTokens && maxTeachTokens) {
      const interfacePercentUsed = (inferenceTokens / maxInferenceTokens) * 100;
      const teachPercentUsed = (teachTokens / maxTeachTokens) * 100;
      return interfacePercentUsed > 80 || teachPercentUsed > 80;
    }
    return false;
  }, [inferenceTokens, teachTokens, maxInferenceTokens, maxTeachTokens]);

  return (
    <Stack direction={'column'} spacing={2}>
      <Card>
        <Stack direction={'column'} p={2}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography>
              Ask Sage Subscription:
            </Typography>
            <Typography>
              {user.plan_type}
            </Typography>
          </Stack>
          <Box>
          <Button component={Link} to={'/payment'} target={'_blank'} color={'primary'} size="small">
            Manage Subscription
          </Button>
          </Box>
        </Stack>
      </Card>
      { !loadingInferenceTokens && !loadingMaxTokens && (
        <TokenCard
          tokensUsed={inferenceTokens}
          tokensTotal={maxInferenceTokens}
          title={'Inference Tokens'}
          loading={loadingInferenceTokens || loadingMaxTokens}
        />
      )}
      {!loadingTeachTokens && !loadingMaxTokens && (
      <TokenCard
        tokensUsed={teachTokens}
        tokensTotal={maxTeachTokens}
        title={'Training Tokens'}
        loading={loadingTeachTokens || loadingMaxTokens}
      />
      )}
      {isLowOnTokens && (
        <Card>
          <Stack direction={'column'} p={2} spacing={1}>
            <Typography variant="h6">
              Running out of tokens?
            </Typography>
            <Typography color={theme.palette.text.secondary}>
              Change your plan to a higher tier to gain access to more tokens.
            </Typography>
            <Typography color={theme.palette.text.secondary}>
              { `Your tokens will reset on ${getFirstDayOfNextMonth().toLocaleDateString()}` }     
            </Typography>
            <Button component={Link} to={'/payment'} target={'_blank'} color={'primary'} size="small" variant={'contained'}>
              Change Subscription
            </Button>
          </Stack>
        </Card>
      )}
    </Stack>
  )
}