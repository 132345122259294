import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress, Alert } from '@mui/material';
import { useDeleteFiles } from './queries'

export const DeleteFilesDialog = ({ open, setOpen, row, refetch }) => {
  const { fetching, warning, error, deleteFile } = useDeleteFiles();

  const handleClose = () => {
    setOpen(false);
  }
  

  const handleDelete = async () => {
    const success = await deleteFile(row.source_string, row.filename);
    if (success) {
      refetch(); 
      setOpen(false);  
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'xs'} fullWidth>
      <DialogTitle>Delete File</DialogTitle>
      { !fetching && (
        <DialogContent>
          Are you sure you want to delete the file {row.filename}?
          {error && (
            <Alert severity='error'>{error}</Alert>
          )}
          {warning && (
            <Alert severity='warning'>{warning}</Alert>
          )}
        </DialogContent>
      )}
      { fetching && (
        <DialogContent sx={{ display: 'flex', alignItems:'center', flexDirection:'column' }}>
          <DialogContentText sx={{mb: 2}}>
            Deleting files...
          </DialogContentText>
          <CircularProgress />
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleDelete} variant='contained' color={'error'}>Delete</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}