import React, { createContext, useContext, useState, useEffect } from 'react';
import * as default_data from '../config.js'

const GoogleOAuthContext = createContext();

export const GoogleOAuthProvider = ({ clientId, children }) => {
  const [isGoogleOAuthEnabled, setIsGoogleOAuthEnabled] = useState(false);

  useEffect(() => {
    if (clientId && default_data.default_has_google_login_enabled === true) {
      setIsGoogleOAuthEnabled(true);
    }
  }, [clientId, default_data.default_has_google_login_enabled]);

  return (
    <GoogleOAuthContext.Provider value={isGoogleOAuthEnabled}>
      {children}
    </GoogleOAuthContext.Provider>
  );
};

export const useGoogleOAuth = () => {
  return useContext(GoogleOAuthContext);
};