import React, { useState } from "react"
import { Box, Card, CardContent, Dialog, DialogTitle, Grid, IconButton, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import { ScrollableDialogContent } from "../Components/ScrollableDialogContent"
import { IoClose } from "react-icons/io5"
import { models } from "../Data/models"
import { getAllowedModels } from '../Utils/model_helper.js'

export const ModelsDialog = ({open, onClose, handleModel, currentUser, paid}) => {
  const [search, setSearch] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('mobile'));

  var allowed_models = getAllowedModels();
  const filteredModels = models.filter((model) => {
    if (model.key === undefined) return false;
    if (!allowed_models.includes(model.key)) return false;
    if (currentUser.type !== 'superadmin' && model.superadmin_only) return false;
    if (model.paid && !paid) return false;
    if (search === '') return true;
    if (typeof model.value !== 'string') return false;
    return model.value.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md" fullScreen={fullScreen}>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Select Model
          <IconButton onClick={onClose}>
            <IoClose />
          </IconButton>
        </Box>
      </DialogTitle>
      <ScrollableDialogContent modalIsOpen={open}>
        <TextField
          width="50%"
          label="Search"
          variant="standard"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <Grid container spacing={2}>
          {filteredModels.map((model) => (
            <Grid item xs={12} mobile={6} key={model.key} sx={{paddingLeft: fullScreen ? '0 !important' : 'unset'}}>
              <Card
                className="prompt-item"
                onClick={()=>{ handleModel({target:{value: model.key}}); onClose(); }}
                variant='outlined'
                sx={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: theme.palette.card.backgroundColor,
                  border: theme.palette.card.border,
                  cursor: 'pointer',
                  '&:hover': theme.palette.card.hover,
                }}
              >
                <CardContent sx={{ padding: 2, ':last-child':{ padding:2}}}>
                  <Typography variant='h6' sx={{ fontSize: '1rem' }}>
                    {model.value}
                  </Typography>
                  <Typography variant='body2' sx={{ fontSize: '0.8rem' }}>
                    {model.comment}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </ScrollableDialogContent>
    </Dialog>
  )
}