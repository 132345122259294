import * as default_data from '../config.js'
import { getUser } from '../AuthService'

const getAllowedModels = () => {
    var cur_user = getUser();
    if (cur_user === undefined || cur_user === null || cur_user.force_models === undefined || cur_user.force_models === null) {
        return default_data.default_allowed_models
    }
    if (Array.isArray(cur_user.force_models) && cur_user.force_models.length > 0) {
        return cur_user.force_models;
    }
    return default_data.default_allowed_models;
}

export { getAllowedModels }
