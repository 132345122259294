import { Button, Link, useTheme, Paper } from '@mui/material'
import * as default_data from '../../../config'

export const Notices = ({ paid, cur_user }) => {
  const theme = useTheme();
  return (
    <Paper sx={{ padding: 2, mt: 2 }}>
      <div>
        <h3 className="modal_header">Important Notices</h3>
        <div className="modal_list">
          <ul> 
            { default_data.default_hide_cac === true ? '' :                                
              <li><strong>WARNING: GOVERNMENT USERS ONLY: your authorizing official must review our ATO evidence package to authorize CUI use. Ask Sage is fully compliant with all NIST / CUI control requirements and we can provide the full package upon request. Please email at <Link sx={{textTransform: 'none', color: theme.palette.textColorSecondary}} href="mailto:support@asksage.ai" rel="noopener" target="_blank">support@asksage.ai</Link>!</strong></li>          
            }
            { default_data.default_hide_cac === true ? '' :                                              
              <li><strong>WARNING: GOVERNMENT USERS ONLY: always store CUI data inside of custom datasets with "-CUI" in its the name. CAC auth is required for CUI access. Only models that have * next to their names are authorized for CUI.</strong></li>          
            }
              <li><strong>INFORMATION: Ask Sage can support any large language models as requested, please reach out to us.</strong></li>          
            { default_data.default_hide_cac === true ? '' :                                                              
              <li><strong>NOTICE: This system and its usage are subject to monitoring, recording, and auditing. Unauthorized use of this system is strictly prohibited and may result in criminal and civil penalties. By using this system, you indicate your consent to monitoring and recording.</strong><br /></li>
            }
            { default_data.default_hide_cac === true ? '' :                                              
              <li><strong>GOVERNMENT USERS ONLY: if you get the error "CAC not found", your local proxy is blocking your CAC. This is NOT something we can fix on our side, you need to reach out to your local IT support to ask *.asksage.ai to be whitelisted for CAC passthrough.</strong><br /><br /></li>
            }                
            { (paid != true && default_data.default_customer_tenant !== true) || (cur_user && cur_user.plan_type && cur_user.plan_type === 'trial') ?
                <div style={{textAlign: 'center', margin: 'auto'}}>
                  <Button variant='contained' href="/payment">Subscribe now for more features & tokens!</Button>
                  <br /><br />
                </div>
                : ''
            }
            <li>Questions? Join our community on Discord: <Link sx={{textTransform: 'none', color: theme.palette.textColorSecondary}} href="https://discord.gg/upxuAdNvjY" rel="noopener" target="_blank">https://discord.gg/upxuAdNvjY</Link>!</li>          
            <li>Having an issue? Email us at <Link sx={{textTransform: 'none', color: theme.palette.textColorSecondary}} href="mailto:support@asksage.ai" rel="noopener" target="_blank">support@asksage.ai</Link>!</li>
            <li>Reached your token limits? Email us at <Link sx={{textTransform: 'none', color: theme.palette.textColorSecondary}} href="mailto:sales@asksage.ai" rel="noopener" target="_blank">sales@asksage.ai</Link>!</li>
          </ul>
        </div>
      </div>
    </Paper>
  );
}