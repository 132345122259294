import { Button } from '@mui/material'
export const FollowUp = ({message, clickHandler, setChatInput}) => {
  const decodeHtml = (html) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  };
  return (
    <Button className="chat-log-followup-btn" size={'small'} value={message} onClick={ (e) => clickHandler(e, setChatInput)}>
      {decodeHtml(message)}
    </Button>
  );
};