import React from 'react';

const AdminHorizontalMenu = ({ type, full_stats }) => {
    return (
      <div className="user-list-menu">
        {type === 'superadmin' && (
          <a href="/superadmin/get-organizations" className="user-list-menu-item">
            Organizations
          </a>
        )}
        <a href={`/${type}/get-users`} className="user-list-menu-item">
          Users
        </a>
        <a href={`/${type}/get-user-stats`} className="user-list-menu-item">
          User consumption
        </a>
        <a href={`/${type}/get-token-stats`} className="user-list-menu-item">
          Token stats
        </a>
        {type === 'superadmin' && (
          <a href="/superadmin/get-stats" className="user-list-menu-item">
            Tenant subscribers stats
          </a>
        )}
      </div>
    );
  };
  
export default AdminHorizontalMenu;