import * as default_data from '../../config.js'
import { getToken, setWithExpiry } from '../../AuthService.js';
import { useState, useEffect, useCallback } from 'react';

export const useFetchMonthlyTokens = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchMonthlyTokens = useCallback(async () => {
    setLoading(true);
    const token = getToken();
    try {
      const response = await fetch(default_data.default_chat_service_url + '/count-monthly-tokens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': `${token}`
        },
        body: JSON.stringify({})
      });
      const result = await response.json();
      if(response){
        setData(result.response);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if(data === null){
      fetchMonthlyTokens();
    }
  }, [fetchMonthlyTokens]);

  return { data, loading, refetch: fetchMonthlyTokens };
};

export const useFetchTeachTokens = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchTeachTokens = useCallback(async () => {
    setLoading(true);
    const token = getToken();
    try {
      const response = await fetch(default_data.default_chat_service_url + '/count-monthly-teach-tokens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': `${token}`
        },
        body: JSON.stringify({})
      });
      const result = await response.json();
      if(response){
        setData(result.response);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if(data === null){
      fetchTeachTokens();
    }
  }, [fetchTeachTokens]);

  return { data, loading, refetch: fetchTeachTokens };
};

export const useFetchMaxTokens = () => {
  const [maxInferenceTokens, setMaxInferenceTokens] = useState(null);
  const [maxTeachTokens, setMaxTeachTokens] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchTeachTokens = useCallback(async () => {
    setLoading(true);
    const token = getToken();
    try {
      const response = await fetch(default_data.default_user_service_url_no_cac + '/validate_token_with_full_user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': `${token}`
        },
        body: JSON.stringify({})
      });
      const result = await response.json();
      if(response){
        setMaxInferenceTokens(result.response.max_tokens)
        setMaxTeachTokens(result.response.max_train_tokens)
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if(maxInferenceTokens === null || maxTeachTokens === null){
      fetchTeachTokens();
    }
  }, [fetchTeachTokens]);

  return { maxInferenceTokens, maxTeachTokens, loading, refetch: fetchTeachTokens };
};

export const useFetchUser = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUser = useCallback(async () => {
    var token = getToken()

    fetch(default_data.default_user_service_url_no_cac + '/validate_token_with_full_user', {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
       'x-access-tokens': `${token}`
     },        
     body: JSON.stringify({
     })
   })
    .then(res => res.json())
    .then(result => {
      setWithExpiry('user', JSON.stringify(result.response))
      setUser(result.response)

      setLoading(false)
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }
  , []);

  useEffect(() => {
    if(user === null){
      fetchUser();
    }
  }, [fetchUser]);

  return { user, loading, refetch: fetchUser };
}