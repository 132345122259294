import React, { useEffect, useRef, useState } from 'react';
import * as default_data from '../../config.js'
import AudioRecorder from '../../Chat/AudioRecorder';
import {
  Box,
  Card,
  Chip,
  Stack,
  useTheme,
  TextField,
  IconButton,
  Typography,
  useMediaQuery
} from '@mui/material'
import { FiSettings, FiCpu, FiFileText  } from "react-icons/fi";
import { useDropzone } from 'react-dropzone';
import { validateFile } from '../../Utils/file_functions.js';
import { ChatInputButton } from './ChatInputButton';
import PromptList from '../../Prompts/PromptList'
import { IoSend } from 'react-icons/io5';
import { ChatInputDrawer } from './ChatInputDrawer';

export const ChatInputNew = ({
  getTokenizer,
  handleSubmit,
  prevCommand,
  setFile,
  currentMode,
  current_dataset,
  handleLive,
  live,
  paid,
  personas,
  handleAudio,
  tokens_count,
  getTokenWindow,
  isCUICapable,
  chatInputPass,
  setChatInputPass,
  handleDetailsClick,
  sources
}) => {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [chatInput, setChatInput] = useState("");
  const pluginListRef = useRef(null);
  const [triggerPromptList, setTriggerPromptList] = useState(0);
  const [PromptListMode, setPromptListMode] = useState("normal");
  const [PromptListValue, setPromptListValue] = useState(null);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('mobile'));
  const [datasetLabel, setDatasetLabel] = useState('None');


  useEffect(() => {
    if (current_dataset && current_dataset.length > 0) {
      const noneDataset = current_dataset.find((dataset) => dataset.value === 'none');
      const allDataset = current_dataset.find((dataset) => dataset.value === 'all');
      if (noneDataset) {
        setDatasetLabel('None');
      } else if (allDataset) {
        setDatasetLabel('All');
      } else {
        if (current_dataset.length === 1) {
          setDatasetLabel(`${current_dataset[0].label}`);
        } else {
          setDatasetLabel(`${current_dataset.length} datasets`);
        }
      }
    }
  }, [current_dataset]);



  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getTokenizer(chatInput)
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [chatInput]);

  useEffect(() => {
    if (chatInputPass !== undefined) {
      setChatInput(chatInputPass)
      setChatInputPass(undefined)
    }
  }, [chatInputPass, setChatInputPass])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (!validateFile(acceptedFiles[0])) {
        alert('Invalid file type. Please upload a valid file type.');
        return;
      }
      setFile(acceptedFiles[0]);
    },
  });

  function handlePromptClick(prompt, persona=1) {
    setChatInput(prompt)
  }

  async function keyHandler(e) {
    if (e.which === 13 && e.ctrlKey) {
      handleSubmit(e, chatInput, setChatInput);
    } else if (e.which === 38 && e.ctrlKey) {
      const chatInputValue = chatInput?.trim() || undefined;
      const newPrevCommand = prevCommand.filter((item, index, self) => {  
        return index === self.indexOf(item);
      });
      if (chatInputValue === '' || chatInputValue === undefined || !newPrevCommand.includes(chatInput)) {
        setChatInput(newPrevCommand[newPrevCommand.length - 1]);
      } else {
        const index = newPrevCommand.indexOf(chatInput);
        if (index > 0) {
          setChatInput(newPrevCommand[index - 1]);
        }
      }
    } else if (e.which === 40 && e.ctrlKey) {
      const chatInputValue = chatInput?.trim() || undefined;
      const newPrevCommand = prevCommand.filter((item, index, self) => {  
        return index === self.indexOf(item);
      });
      if (chatInputValue === '' || chatInputValue === undefined || !newPrevCommand.includes(chatInput)) {
        setChatInput(newPrevCommand[0]);
      } else {
        const index = newPrevCommand.indexOf(chatInput);
        if (index < newPrevCommand.length - 1) {
          setChatInput(newPrevCommand[index + 1]);
        }
      }
    }
  }

  const file_style = {
    fileInput: {
      display: 'none', 
    },
    fileLabel: {
      background: 'url(file.png) no-repeat'
    }
  };

  function showPromptList(type='normal', field_name) {
    setTriggerPromptList(1);
    setPromptListMode(type);
    setPromptListValue(field_name);
  }

  return (
    <>
      <Box sx={{ position: 'relative', marginRight:1}}>
        {/* Buttons Container */}
        <Stack 
          direction="row" 
          spacing={1} 
          sx={{
            position: 'absolute', 
            top: isMobile ? -38 : -44, 
            left: 0, 
            right: 0,
            zIndex: 2, 
            marginX: 2,
            borderRadius: '0 0 8px 8px',
            justifyContent: isMobile ? 'space-between' : 'center',
            maxHeight: isMobile ? 32 : 52,
          }}
        >
          {isMobile && (
            <Stack direction="row" spacing={1} style={{ alignItems: 'center' }}>
              <Box 
                sx={{
                  backgroundColor: theme.palette.chatBackground,
                  borderRadius: 2,
                  border: '1px solid white',
                }}
              >
                {default_data.default_voicerecorder_enabled === true && paid == true && (
                  <AudioRecorder handleAudio={handleAudio} chatInput={chatInput} setChatInput={setChatInput} isMobile />
                )}
              </Box>
            </Stack>
          )}
          <Stack direction="row" spacing={1} justifyContent={isMobile ? 'end' : 'center'} width={'100%'}>
          { sources && sources.length > 0 ? 
            <ChatInputButton
              onClick={() => {handleDetailsClick()}}
              startIcon={<FiCpu size={18} />}
            >
              Actions
            </ChatInputButton>
            : ''}
            <ChatInputButton
              onClick={() => showPromptList('normal')}
              startIcon={<FiFileText size={18} />}
            >
              Prompts
            </ChatInputButton>
            <ChatInputButton
              onClick={() => {setDrawerOpen(true)}}
              startIcon={<FiSettings size={18} />}
            >
              Advanced
            </ChatInputButton>
          </Stack>
        </Stack>
        <Box sx={{boxShadow: `0 -30px 10px ${theme.palette.chatBackground}`}}>
          <Card 
            variant='outlined'
            sx={{
              backgroundColor: theme.palette.chatInput.backgroundColor,
              marginX: 2,
              overflow: 'unset',
              paddingX: 1,
              paddingY: 0.5,
              backgroundImage: 'none',
              borderRadius: 3,
              border: '1px solid white',
            }}
          >
            <Stack direction="row" spacing={0} alignItems={'end'} {...getRootProps()} onClick={()=>{}}>
              {!isMobile && (
                <Stack direction="row" spacing={0} style={{ marginBottom: '4px' }}>
                  {default_data.default_voicerecorder_enabled === true && paid == true && (
                    <AudioRecorder handleAudio={handleAudio} chatInput={chatInput} setChatInput={setChatInput}  />
                  )}
                </Stack>
              )}
              <input {...getInputProps()} onClick={()=>{}} onChange={()=>{}} />
              <TextField
                value={chatInput}
                fullWidth
                sx={{
                  display: 'block',
                  width: '100%',
                  backgroundColor: theme.palette.chatInput.backgroundColor,
                  resize: 'vertical',
                  color: theme.palette.text.primary,
                  '& .MuiInputBase-root': {
                    color: theme.palette.text.primary,
                    padding: isMobile ? 0.5 : 1,
                    border: 'none',
                    justifyContent: 'center',
                  },
                }}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                onKeyDown={(e) => keyHandler(e)}
                onChange={(e)=> setChatInput(e.target.value)}
                placeholder="Ask Sage Anything..."
                minRows={1}
                maxRows={8}
                multiline
              />
              <IconButton
                onClick={(e) => handleSubmit(e, chatInput, setChatInput)}
                size={isMobile ? 'small' : 'medium'}
              >
                <IoSend />
              </IconButton>
            </Stack>
          </Card>
        </Box>
        <Stack direction="row" spacing={0} style={{ marginBottom: '4px', width: '100%' }} justifyContent={'space-between'} paddingX={2}>
          <Stack direction="row" spacing={0} alignItems={'center'} marginTop={'4px'}>
          </Stack>
          <Stack direction="row" spacing={0} alignItems={'center'}>
            <Box className="file-info" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {live !== 0 && !isMobile && (
                <Chip sx={{marginLeft: 1}} label={live === 1 ? 'Live' : 'Live+'} color="success" size="small" onClick={()=>setDrawerOpen(true)} />
              )}
              {!default_data.default_hide_cac && (
                isCUICapable() ?
                  <Chip label={isMobile ? 'CUI' : 'CUI compliant'} color="success" size="small" />
                :
                  <Chip label={isMobile ? 'Not CUI' : 'Not CUI compliant'} color="error" size="small" />
              )}
              <Typography variant="body2" sx={{ color: tokens_count > getTokenWindow() ? 'error.main' : 'inherit' }}>
                {tokens_count.toLocaleString()}{!isMobile && ` / ${getTokenWindow().toLocaleString()}`} tokens
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      {triggerPromptList === 1 && <PromptList setTriggerPromptList={setTriggerPromptList} triggerPromptList={triggerPromptList} personas={personas} handlePromptClick={handlePromptClick} />}
      <ChatInputDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        live={live}
        handleLive={handleLive}
        paid={paid}
        currentMode={currentMode}
      />
    </>
  )  
}
