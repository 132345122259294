import React, { useEffect, useState } from 'react';  
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Stack, IconButton, Box, Alert } from '@mui/material';
import { FiPlus, FiX } from 'react-icons/fi';
import { getUser } from '../../AuthService';
import { assignUserToDataset, deassignUserFromDataset, fetchAssignedUsers } from './queries';
import { BsPerson } from 'react-icons/bs';

export const ShareDatasetDialog = ({
  datasetToShare,
  open,
  setOpen,
}) => {
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [queryError, setQueryError] = useState(undefined);
  const [assignedUsers, setAssignedUsers] = useState([]);

  const user = getUser();
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;

  const handleSubmit = () => {
    const emailsCopy = [...emails];
    if(email.length > 0) {
      if (isEmailValid(email)) {
        emailsCopy.push(email);
        setEmail('');
        setEmails(emailsCopy);
      }
    }
    setLoading(true);
    emailsCopy.forEach((email) => {
      assignUserToDataset(datasetToShare, email)
        .then((response) => {
          if (response.status === 200) {
            setMessage('Dataset shared successfully');
            setEmails(emails.filter((e) => e !== email));
            setAssignedUsers([...assignedUsers, email]);
            setQueryError(undefined);
          } else {
            setQueryError(`${email}: Failed to share dataset - ${response.response}`);
          }
        }
      )
    })
    setLoading(false);
  }

  const deassignUser = (email) => {
    deassignUserFromDataset(datasetToShare, email)
      .then((response) => {
        if (response.status === 200) {
          setAssignedUsers(assignedUsers.filter((user) => user !== email));
          setMessage('User removed successfully');
          setQueryError(undefined);
        } else {
          setQueryError(`${email}: Failed to share dataset - ${response.response}`);
        }
      }
    )
  }

  const getAssignedUsers = () => {
    fetchAssignedUsers(datasetToShare)
      .then((response) => {
        if (response.status === 200) {
          setAssignedUsers(JSON.parse(response.response));
        } else {
          setQueryError(`Failed to fetch assigned users - ${response.response}`);
        }
      })
      .catch((error) => {
        setQueryError(`An error occurred while fetching assigned users - ${error.message}`);
      });
  };

  const isEmailValid = (email) => {  
    if (emailPattern.test(email)) {
      return true;
    } else {
      setError('Invalid email address');
      return false;
    }
  }

  useEffect(() => {
    if(assignedUsers.length === 0) {
      getAssignedUsers();
    }
  }, [datasetToShare])

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Share Dataset</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Typography>Enter the email addresses of the people you would like to share this dataset with.</Typography>
          {queryError && <Alert severity='error'>{queryError}</Alert>}
          {message && <Alert severity='success'>{message}</Alert>} 
          <Typography>Assigned Users:</Typography>
          <Stack spacing={0.5}>
            {assignedUsers.map((userEmail, index) => (
              <Stack key={index} direction={'row'} spacing={0.5} alignItems={'center'}>
                <BsPerson />
                <Typography key={index}>{userEmail}</Typography>
                {userEmail !== user.email && (
                  <IconButton
                    aria-label='remove email'
                    size='small'
                    color="error"
                    onClick={() => deassignUser(userEmail)}
                  >
                    <FiX/>
                  </IconButton>
                )}
              </Stack>
            ))}
          </Stack>
          {emails.length > 0 && ( <Typography>Users to add:</Typography> )}
          {emails.map((email, index) => (
            <Stack key={index} direction={'row'} spacing={0.5} alignItems={'center'}>
              <BsPerson />
              <Typography>{email}</Typography>
              <IconButton
                aria-label='remove email'
                size='small'
                color="error"
                onClick={() => setEmails(emails.filter((e) => e !== email))}
              >
                <FiX/>
              </IconButton>
            </Stack>
          ))} 
        </Stack>
        <Stack spacing={2} direction={'row'} mt={3} justifyContent={'top'}>
          <TextField
            label="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
              setError(undefined)
            }}
            size='small'
            error={error !== undefined}
            helperText={error}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                if (isEmailValid(email)) {
                  setEmails([...emails, email]);
                  setEmail('');
                }
              }
            }}
            fullWidth
          />
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'flex-start', marginTop: '6px !important' }}>
            <IconButton
              aria-label='add email'
              size='small'
              sx={{
                height: '100%',
                alignSelf: 'flex-start',
              }}
              onClick={()=> {
                if (isEmailValid(email)) {
                  setEmails([...emails, email]);
                  setEmail('');
                }
              }}
            >
              <FiPlus />
            </IconButton>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>
          Share
        </Button>
        <Button onClick={() => setOpen(false)} color="secondary" disabled={loading}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
} 